import { BaseApiController, AuthProvider } from '@mit/aws-react'
import apiConfig from '../api-config'
import appConfig from '../../app-config-global'
import { JsonMapType } from '@mit/aws-react/src/types/index'

export interface ApiResponse {
  error?: string
}

export class ApiController extends BaseApiController {
  // Override any methods from TemplateConstant BaseApiController here. Extend all other controllers off this controller
  readonly apiPath: string
  // public readonly appConfig: AppConfig
  // private readonly activeRegion: RegionalConfig
  public readonly apiHost: string
  public readonly apiPathDigitalId: string

  constructor () {
    super()

    const isGlobalEnabled = localStorage.getItem('isGlobalEnabled') === 'true' ?? false
    const configKey = isGlobalEnabled ? 'global' : 'regional'

    this.apiHost = apiConfig[configKey].apiHost ?? ''
    this.apiPathDigitalId = apiConfig[configKey].paths.digitalId ?? ''
    this.apiPath = apiConfig[configKey].paths.apiPath ?? ''
  }

  async makeRequest (
    method: string,
    fetchKey: string,
    url: string,
    body: JsonMapType | null = null,
    overrideHeaders: Record<string, string> = {},
    file: boolean = false,
    impersonateKerb: string = ''
  ): Promise<JsonMapType> {
    const request = {
      apiHost: this.apiHost,
      apiPath: this.apiPath
    }
    const requestPath = `${request.apiHost}/${request.apiPath}`
    const additionalHeaders = {}

    return await this.useFetch(
      method,
      fetchKey,
      `${requestPath}/${url}`,
      body,
      {
        ...overrideHeaders,
        ...additionalHeaders
      },
      file,
      impersonateKerb
    )
  }

  isGoodStatus (status: number): boolean {
    return status >= 200 && status < 300
  }

  returnError (errorText: string): ApiResponse {
    return {
      error: errorText
    }
  }

  /*
    If you do not use cognito, implement your authorization header logic here
  */
  async getAuthorizationHeaders (): Promise<Record<string, string>> {
    // const authProv = await new AuthProvider(this.activeRegion)
    const token = await new AuthProvider(Object.assign(appConfig)).getToken()

    if (token !== '') {
      return {
        Authorization: `Bearer ${token}`
      }
    } else {
      return {
        Authorization: 'Bearer'
      }
    }
  }
}
