import { createContext, FC, ReactElement, ReactNode, useContext, useState } from 'react'

export const AppContext = createContext({})

interface Props {
  children: ReactNode
}

export const AppProvider: FC<Props> = ({ children }): ReactElement => {
  const [hasAccess, setHasAccess] = useState<boolean>()
  const [isLoading, setIsLoading] = useState<boolean>()
  const [isApprover, setIsApprover] = useState<boolean>()
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>()
  const [isBillingAdmin, setIsBillingAdmin] = useState<boolean>()
  const exportValue: any = {
    hasAccess,
    setHasAccess,
    isLoading,
    setIsLoading,
    isApprover,
    setIsApprover,
    isSuperAdmin,
    setIsSuperAdmin,
    isBillingAdmin,
    setIsBillingAdmin
  }

  return <AppContext.Provider value={exportValue}>{children}</AppContext.Provider>
}

export const useAppContext = (): any => {
  const context = useContext(AppContext)

  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppProvider')
  }
  return context
}
