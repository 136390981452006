import React, { FC } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useStoreState, CoreProvider } from 'Store'
import { Helmet } from 'react-helmet'
import reportWebVitals from './reportWebVitals'
import 'Scss/main.scss'
import { Initialization } from '@mit/aws-react'
import { useAppInitializer } from './Hooks'
import appConfig from './app-config-global'
import { AppProvider } from './context/app/app-context'
import { MITLoggedOut } from '@mit/hui'

const WrappedApp: FC = () => {
  const AppLazyLoad = React.lazy(
    async () =>
      await import('Components/app/App').then(module => ({
        default: module.default
      }))
  )

  const { appName, appContact, appDescription } = useStoreState(state => state.AppConfigModel)
  const { initializeApp } = useAppInitializer()
  return (
    <>
      <Helmet titleTemplate={`${appName} - %s`} defaultTitle={appName}>
        <meta name='description' content={appDescription} />
      </Helmet>
      <Router>
        <React.StrictMode>
          <Switch>
            <Route exact path='/logout' component={MITLoggedOut} />
            <Route path='/'>
              <Initialization
                App={AppLazyLoad}
                appInitializer={initializeApp}
                appConfigData={appConfig}
                loaderProps={{
                  contactEmail: appContact,
                  name: appName,
                  isLoading: true,
                  type: 'Default',
                  backgroundColor: '#20447D'
                }}
              />
            </Route>
          </Switch>
        </React.StrictMode>
      </Router>
    </>
  )
}

ReactDOM.render(
  <CoreProvider>
    <AppProvider>
      <WrappedApp />
    </AppProvider>
  </CoreProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
