import cloudAccountsApi from '../Api/cloud-accounts-api'
import { useAppContext } from '../context/app/app-context'
import { appInitialized } from '@mit/aws-react'
import { useEffect } from 'react'

interface UseAppInitializationReturn {
  initializeApp: () => void
}

export const useAppInitializer = (): UseAppInitializationReturn => {
  const { setIsApprover, setIsSuperAdmin, setIsBillingAdmin }: any = useAppContext()

  useEffect(() => {
    appInitialized({
      isLoading: true,
      hasAccess: false,
      hasError: false
    })
  }, [])

  const initializeApp = async (): Promise<void> => {
    try {
      const result = await cloudAccountsApi.fetchAuthorization()
      if (result.error === undefined) {
        appInitialized({
          isLoading: false,
          hasAccess: true,
          hasError: false
        })
        setIsApprover(result.item?.isApprover === true)
        setIsSuperAdmin(result.item?.isSuperAdmin === true)
        setIsBillingAdmin(result.item?.isBillingAdmin === true)
      } else {
        appInitialized({
          isLoading: false,
          hasAccess: false,
          hasError: true
        })
      }
    } catch (e) {
      console.error(e)
      appInitialized({
        isLoading: false,
        hasAccess: false,
        hasError: false
      })
    }
  }
  return { initializeApp }
}
