const apiConfigs = {
  "regional": {
    "apiHost": "https://api.mit.edu",
    "paths": {
      "apiPath": "cloud-accounts-v1",
      "digitalId": "digital-id-v1"
    }
  },
  "global": {
    "apiHost": "https://cloud-accounts.mit.edu/api",
    "paths": {
      "apiPath": "cloud-accounts/v1",
      "digitalId": "digital-id/v1"
    }
  }
};
export default apiConfigs;