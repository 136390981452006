import { AtlasTheme } from '@mit/hui'

interface State {
  appName: string
  appDescription: string
  appContact: string
  appTheme: AtlasTheme
  googleAnalyticsId: string
}
// eslint-disable-next-line
interface Actions {}
// eslint-disable-next-line
interface Thunks {}

export interface IAppConfigModel extends State, Actions, Thunks {}

export const AppConfigModel: IAppConfigModel = {
  appName: 'MIT Cloud Accounts',
  appDescription: 'MIT Cloud Accounts web application.',
  appContact: 'help-desk@mit.edu',
  appTheme: 'default',
  googleAnalyticsId: 'g-xxxxxxxx'
}
