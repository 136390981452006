import { ApiResponse, ApiController } from './controller'
import { CloudAccount } from '../types/account'
import { Authorization } from '../types/authorization'
import { RequestInterface } from '../types/request-interface'
import { ProviderSettings } from '../types/proivder-settings-interface'
import { GetInvoiceI } from '../types/invoice-interface'
import { InvoiceDetailI } from '../types/invoice-detail-interface'
import { ErrorI } from '../types/automation-error'
import { AdminDataI } from '../types/admin-data'
import { InvoiceEmail } from '../types/invoice-email'
import { GetGcpBillingAccountI } from '../types/gcp-billing-account'
import { CostObjectI } from '../types/cost-object'
import { EditInterface } from '../types/edit-interface'
import { BillingSnapshot } from '../types/billing-snapshot'
import { NihProject } from '../types/nih/nih'
import { BillingReport } from '../types/billing-report'
import { BillingEntityProductCodeTotal, BillingEntityTotal } from '../types/billing-entity-total'
import { EditContactsI } from '../types/edit-contact-interface'
import { ContactInterface } from '../types/contact'
import { UserInterface } from '../types/user'
import { EditMembersI } from '../types/edit-member-interface'

interface GcpBillingAccountsResponse extends ApiResponse {
  items: GetGcpBillingAccountI[]
}

interface InvoicesResponse extends ApiResponse {
  items: GetInvoiceI[]
}

interface InvoiceResponse extends ApiResponse {
  item?: InvoiceDetailI
}

interface AutomationErrorsResponse extends ApiResponse {
  items: ErrorI[]
}

interface AdminDataResponse extends ApiResponse {
  item?: AdminDataI
}

interface AccountsResponse extends ApiResponse {
  items: CloudAccount[]
}

interface AccountsContactsResponse extends ApiResponse {
  items: ContactInterface[]
}

interface AccountsMembersResponse extends ApiResponse {
  items: UserInterface[]
}

interface BurnDownResponse extends ApiResponse {
  entityTotals?: BillingEntityTotal[]
  productTotals?: BillingEntityProductCodeTotal[]
  beginDate?: string
  endDate?: string
  commitment?: number
}

interface AccountResponse extends ApiResponse {
  item?: RequestInterface
}

interface ProvidersResponse extends ApiResponse {
  items?: ProviderSettings[]
}

interface ItemsResponse<T> extends ApiResponse {
  items?: T[]
}

interface InvoiceEmailResponse extends ApiResponse {
  items?: InvoiceEmail
}

interface AuthorizationResponse extends ApiResponse {
  item?: Authorization
}

interface SnapshotResponse extends ApiResponse {
  item?: BillingSnapshot
}

interface BasicResponse extends ApiResponse {
  success: boolean
}

interface NewRequestResponse extends ApiResponse {}

const toDateStr = (date: Date): string => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

interface ApprovalActionI {
  action: 'APPROVE' | 'REJECT'
  approver_comment?: string
  credits?: number
  credits_end_date?: string
  billing_account_id?: string
}

interface DeleteResponse extends ApiResponse {
  success: boolean
  error?: string
}

interface ValidateResponseI extends ApiResponse {
  valid: boolean
  error?: string
}

interface CostObjectResponseI extends ApiResponse {
  item?: CostObjectI
}

interface NihProjectResponseI extends ApiResponse {
  item?: NihProject
}

export interface EditContactResponse extends ApiResponse {
  success: boolean
  items?: ContactInterface[]
}

export interface EditMemberResponse extends ApiResponse {
  success: boolean
  items?: UserInterface[]
}

export interface EditResponse extends ApiResponse {
  success: boolean
  item?: RequestInterface
}

class CloudAccountsApi extends ApiController {
  async fetchApproveOrReject (
    id: string,
    action: 'APPROVE' | 'REJECT',
    billingAccount?: string,
    comment?: string,
    credits?: number,
    creditsEndDate?: Date
  ): Promise<BasicResponse> {
    const body: ApprovalActionI = {
      action: action,
      approver_comment: comment
    }
    if (action === 'APPROVE' && credits !== undefined && creditsEndDate !== undefined && creditsEndDate !== null) {
      body.credits = credits
      body.credits_end_date = `${toDateStr(creditsEndDate)}`
    }
    if (billingAccount !== undefined) {
      body.billing_account_id = billingAccount
    }
    const response = await this.makeRequest('PUT', 'accounts', `approval-items/${id}`, body)

    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        success: data.success === true,
        error: data.error
      }
    } else {
      return { success: false, ...this.returnError(await response.text()) }
    }
  }

  async fetchAccountCreated (id: string, provisionedId: string, email: string): Promise<BasicResponse> {
    const response = await this.makeRequest('PUT', 'accounts', `approval-items/${id}`, {
      action: 'CREATED',
      provisioned_id: provisionedId,
      account_email: email
    })

    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        success: data.success === true,
        error: data.error
      }
    } else {
      return { success: false, ...this.returnError(await response.text()) }
    }
  }

  async fetchPostRequest (request: RequestInterface): Promise<NewRequestResponse> {
    const response = await this.makeRequest('POST', 'accounts', 'accounts', {
      request
    })
    if (this.isGoodStatus(response.status)) {
      return {}
    } else {
      return this.returnError(await response.text())
    }
  }

  async fetchAuthorization (): Promise<AuthorizationResponse> {
    try {
      const response = await this.makeRequest('GET', 'accounts', 'authorization')
      if (this.isGoodStatus(response.status)) {
        return {
          item: await response.json()
        }
      } else {
        return this.returnError(await response.text())
      }
    } catch (e) {
      return this.returnError('Error fetching authorization')
    }
  }

  async fetchApprovalItems (): Promise<AccountsResponse> {
    const response = await this.makeRequest('GET', 'accounts', 'approval-items')
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        items: data.message
      }
    } else {
      const text = await response.text()
      return {
        items: [],
        error: text
      }
    }
  }

  async fetchErrors (): Promise<AutomationErrorsResponse> {
    const response = await this.makeRequest('GET', 'accounts', 'errors')
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        items: data.items
      }
    } else {
      const text = await response.text()
      return {
        items: [],
        error: text
      }
    }
  }

  async fetchAdminData (): Promise<AdminDataResponse> {
    const response = await this.makeRequest('GET', 'accounts', 'admin')
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        item: data
      }
    } else {
      const text = await response.text()
      return {
        error: text
      }
    }
  }

  async fetchAllAccounts (): Promise<AccountsResponse> {
    const response = await this.makeRequest('GET', 'accounts', 'accounts?allAccounts=Y')
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        items: data.message
      }
    } else {
      const text = await response.json()
      return {
        items: [],
        error: text.message
      }
    }
  }

  async fetchBurnDown (): Promise<BurnDownResponse> {
    const response = await this.makeRequest('GET', 'accounts', 'billing-reports/aws-usage')
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        productTotals: data.productTotals,
        entityTotals: data.entityTotals,
        beginDate: data.beginDate,
        endDate: data.endDate,
        commitment: data.commitment
      }
    } else {
      const text = await response.json()
      return {
        error: text.message
      }
    }
  }

  async fetchMyAccounts (): Promise<AccountsResponse> {
    const response = await this.makeRequest('GET', 'accounts', 'accounts')
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        items: data.message
      }
    } else {
      const text = await response.json()
      return {
        items: [],
        error: text.message
      }
    }
  }

  async fetchInvoiceExport (id: number): Promise<InvoicesResponse> {
    const response = await this.makeRequest('GET', 'accounts', `billing-reports/${id}`)
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        items: data
      }
    } else {
      const text = await response.json()
      return {
        items: [],
        error: text.message
      }
    }
  }

  async fetchMyAccountsContacts (id: string): Promise<AccountsContactsResponse> {
    const response = await this.makeRequest('GET', `contacts-${id}`, `accounts/${id}/contacts`)
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        items: JSON.parse(data.message)
      }
    } else {
      const text = await response.json()
      return {
        items: [],
        error: text.message
      }
    }
  }

  async fetchMyAccountsMembers (id: string): Promise<AccountsMembersResponse> {
    const response = await this.makeRequest('GET', `members-${id}`, `accounts/${id}/members`)
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        items: JSON.parse(data.message)
      }
    } else {
      const text = await response.json()
      return {
        items: [],
        error: text.message
      }
    }
  }

  async fetchGenerateReport (year: string, month: string): Promise<BasicResponse> {
    const response = await this.makeRequest('POST', 'accounts', `billing-reports?year=${year}&month=${month}`)
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        success: data
      }
    } else {
      const text = await response.json()
      return {
        success: false,
        error: text.message
      }
    }
  }

  async fetchBillingReports (): Promise<ItemsResponse<BillingReport>> {
    const response = await this.makeRequest('GET', 'accounts', 'billing-reports')
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        items: data
      }
    } else {
      const text = await response.json()
      return {
        error: text.message
      }
    }
  }

  async fetchInvoices (): Promise<InvoicesResponse> {
    const response = await this.makeRequest('GET', 'accounts', 'invoices')
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        items: data.items
      }
    } else {
      const text = await response.json()
      return {
        items: [],
        error: text.message
      }
    }
  }

  async fetchGcpBillingAccounts (): Promise<GcpBillingAccountsResponse> {
    const response = await this.makeRequest('GET', 'gcp/billing-accounts', 'gcp/billing-accounts')
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        items: data
      }
    } else {
      const text = await response.json()
      return {
        items: [],
        error: text.message
      }
    }
  }

  async fetchInvoice (requestId: number, year: string, month: string): Promise<InvoiceResponse> {
    const response = await this.makeRequest('GET', 'accounts', `invoices/${requestId}?year=${year}&month=${month}`)
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        item: data
      }
    } else {
      const text = await response.json()
      return {
        error: text.message
      }
    }
  }

  async fetchInvoicePdf (requestId: number, year: string, month: string): Promise<any> {
    const response = await this.makeRequest('GET', 'accounts', `invoices/pdf/${requestId}?year=${year}&month=${month}`)
    if (this.isGoodStatus(response.status)) {
      const data = await response.blob()
      return data
    } else {
      const text = await response.json()
      return {
        error: text.message
      }
    }
  }

  async fetchAccount (id: string): Promise<AccountResponse> {
    const response = await this.makeRequest('GET', 'accounts', `accounts/${id}`)
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      const parsed = JSON.parse(data.message)
      return {
        item: {
          ...parsed
        }
      }
    } else {
      return this.returnError(await response.text())
    }
  }

  async fetchProviders (): Promise<ProvidersResponse> {
    const response = await this.makeRequest('GET', 'accounts', 'providers')
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        items: data.message
      }
    } else {
      return this.returnError(await response.text())
    }
  }

  async fetchInvoiceEmail (year: string, month: string): Promise<InvoiceEmailResponse> {
    const response = await this.makeRequest('POST', 'accounts', `invoices/email?year=${year}&month=${month}&simulate=Y`)
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        items: data
      }
    } else {
      return this.returnError(await response.text())
    }
  }

  async fetchSendEmails (year: number, month: number, emailCode: string): Promise<InvoiceEmailResponse> {
    const response = await this.makeRequest('POST', 'accounts', `invoices/email?year=${year}&month=${month}&simulate=N&emailCode=${emailCode}`)
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        items: data
      }
    } else {
      return this.returnError(await response.text())
    }
  }

  async fetchDeleteRequest (id: string): Promise<DeleteResponse> {
    const response = await this.makeRequest('DELETE', 'accounts', `accounts/${id}`)
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        success: data.success
      }
    } else {
      return {
        success: false,
        error: await response.text()
      }
    }
  }

  async validateField (type: 'costObject' | 'nihProjectNumber', value: string): Promise<ValidateResponseI> {
    const response = await this.makeRequest('GET', 'accounts', `validate?type=${type}&value=${value}`)
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        valid: data.valid
      }
    } else {
      return {
        valid: false,
        error: await response.text()
      }
    }
  }

  async getCostObject (value: string): Promise<CostObjectResponseI> {
    const response = await this.makeRequest('GET', 'accounts', `cost-object?value=${value}`)
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      if (data.status === '') {
        return this.returnError('Cost object not found.')
      }
      return {
        item: data
      }
    } else {
      return this.returnError(await response.text())
    }
  }

  async getNihProjectDetail (value: string): Promise<NihProjectResponseI> {
    const response = await this.makeRequest('GET', 'accounts', `nih?project_number=${value}`)
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      if (data.status === '') {
        return this.returnError('Project not found.')
      }
      return {
        item: data.item
      }
    } else {
      return this.returnError(await response.text())
    }
  }

  async putEditRequest (id: string, value: EditInterface): Promise<EditResponse> {
    const response = await this.makeRequest('PUT', 'accounts', `accounts/${id}`, value)
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()

      if (data.success === true) {
        const updatedAccount = await this.fetchAccount(id)
        if (updatedAccount.item !== undefined) {
          return {
            success: data.success === true,
            error: data.success !== true ? data.error ?? 'Internal server error.' : undefined,
            item: updatedAccount.item
          }
        }
      }
      return {
        success: data.success === true,
        error: data.success !== true ? data.error ?? 'Internal server error.' : undefined
      }
    } else {
      return { success: false, ...this.returnError(await response.text()) }
    }
  }

  async editContactRequest (id: string, value: EditContactsI): Promise<EditContactResponse> {
    const response = await this.makeRequest('PUT', 'accounts', `accounts/${id}/contacts`, value)
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()

      if (data.success === true) {
        const updatedAccount = await this.fetchMyAccountsContacts(id)
        if (updatedAccount.items !== undefined) {
          return {
            success: data.success === true,
            error: data.success !== true ? data.error ?? 'Internal server error.' : undefined,
            items: updatedAccount.items
          }
        }
      }
      return {
        success: data.success === true,
        error: data.success !== true ? data.error ?? 'Internal server error.' : undefined
      }
    } else {
      return { success: false, ...this.returnError(await response.text()) }
    }
  }

  async deleteContactRequest (id: string, contactType: string): Promise<DeleteResponse> {
    const response = await this.makeRequest('DELETE', 'accounts', `accounts/${id}/contacts/${contactType}`)
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        success: data.success
      }
    } else {
      return {
        success: false,
        error: await response.text()
      }
    }
  }

  async editMemberRequest (id: string, value: EditMembersI): Promise<EditMemberResponse> {
    const response = await this.makeRequest('PUT', 'accounts', `accounts/${id}/members`, value)
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()

      if (data.success === true) {
        const updatedAccount = await this.fetchMyAccountsMembers(id)
        if (updatedAccount.items !== undefined) {
          return {
            success: data.success === true,
            error: data.success !== true ? data.error ?? 'Internal server error.' : undefined,
            items: updatedAccount.items
          }
        }
      }
      return {
        success: data.success === true,
        error: data.success !== true ? data.error ?? 'Internal server error.' : undefined
      }
    } else {
      return { success: false, ...this.returnError(await response.text()) }
    }
  }

  async deleteMemberRequest (id: string, krbName: string): Promise<DeleteResponse> {
    const response = await this.makeRequest('DELETE', 'accounts', `accounts/${id}/members/${krbName}`)
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        success: data.success
      }
    } else {
      return {
        success: false,
        error: await response.text()
      }
    }
  }

  async getSnapshot (id: string): Promise<SnapshotResponse> {
    const response = await this.makeRequest('GET', 'accounts', `invoices/${id}/snapshot`)
    if (this.isGoodStatus(response.status)) {
      const data = await response.json()
      return {
        item: data
      }
    } else {
      return { ...this.returnError(await response.text()) }
    }
  }

  async performBurwoodSync (): Promise<boolean> {
    const response = await this.makeRequest('POST', 'accounts', 'api?action=BURWOOD_SYNC_DATA')
    console.log(response)
    if (this.isGoodStatus(response.status)) {
      const data: any = await response.json()
      return data.success
    } else {
      return false
    }
  }
}

const cloudAccountsApi = new CloudAccountsApi()
export default cloudAccountsApi
